import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import { default as Old2005BlogPage, doNothingAnchor } from "../../components/Old2005BlogPage";

const OldBlogPage = () => (
  <Layout>
    <Seo title="Old 2005 Blog—September 2005" />
	<Old2005BlogPage>
		<h2>September 2005</h2>






		<h3 className="blogdate">
		Saturday, September 24, 2005
		</h3>






		<h4 className="blogitemtitle">
		Rockhampton blues
		</h4>





			<div className="blogitembody">
			So now I am in Rockhampton, Queensland...  Beef capitol of Australia, they say!  I am currently trying to procure a gig or two from one of the many hotel pubs, and there isn't even a place to busk here.  I just worked a week doing some cleaning and painting at a house renovation, so that will keep me living for a bit!
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 1:23 PM &nbsp;
			</div>











		<h4 className="blogitemtitle">
		Rebecca Wittwer launches music site
		</h4>





			<div className="blogitembody">
			I just stole a few minutes to browse through my great friend's new music web site! It is totally good! I am not able to listen to the mp3's yet because I am homeless in Australia, wandering around as a starving mus-o. But PLEASE check Rebecca out, she is so very inspiring and to know her is to love her!<br /><br />Links:<br /><a href="/" onClick={doNothingAnchor}>http://www.rebecca-syndrome.ca</a>
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 1:19 PM &nbsp;
			</div>






		<h3 className="blogdate">
		Monday, September 12, 2005
		</h3>






		<h4 className="blogitemtitle">
		Funkenstein at the Swan Basement, Fremantle
		</h4>





			<div className="blogitembody">
			I arrived at Jack's house last night in North Fremantle and, immediately after meeting him, was handed a scotch to drink.  I knew we would be friends!  After some talk of living and travelling in Australia and Canada (and after a few more scotches), we dove into the Swan Basement, a music venue at the Swan Hotel, which is near the Swan River in North Fremantle.  $15 at the door was worthwhile for Funkenstein, an evening of funky live music.  I saw three bands blast out wicked funky tunes, complete with falsetto voices, trumpets, saxes, and wah guitars!  The room was lounge-lit, with disco light crawling up red velvet curtains.  Old, beat sofas surrounded the dance floor, where character Perth locals alternated between shaking and resting their asses.  I had a great time, buying expensive rounds of drinks with Jack!  As I crossed the Swan River on my way home (my hostel), I was captivated by the monstrous cargo and freight ships in the port, lit by the lights of the cargo cranes that filled or emptied their bellies.  You should spend some time in Freo... it's cool!
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 5:11 AM &nbsp;
			</div>






		<h3 className="blogdate">
		Sunday, September 11, 2005
		</h3>






		<h4 className="blogitemtitle">
		Little Creatures Brewery, Fremantle
		</h4>





			<div className="blogitembody">
			It was a cold, late-winter evening in Fremantle last night, and even though I am Canadian I did not have the clothing to feel comfortable.  Sebastian, Viola, Jana and I found Little Creatures Brewery, next to the harbour, to be a satisfying place to warm up.  We bought little glases of Pale Ale and sat at our own booth, next to the brewing and fermenting vats of beer.  All the tables are lit by tea light candles, and the upper balcony's rail is laced with string lights.  There are bigger, cylinder candles posted on ledges and bar counters, and warmth emanates everywhere.  The kitchen is completely open, seperated from the public floor by counter/bar.  I gazed at glowing embers inside a stone/forno oven and knew immediately that I would try a pizza...  mushroom, anchovy, and olive to be specific.  Sebastian and I debated passion fruit cheesecake with hungry eyes, but pizza was a great choice!  The ambience was perfect for mingling with others, sitting or standing, but we kept to ourselves.  When we returned to our hostel we realized that our clothes were still fresh smelling... no smoke!  Perhaps Little Creatures is a smoke-free pub?<br /><br />Links:<br /><a href="http://www.littlecreatures.com.au/">http://www.littlecreatures.com.au/</a>
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 5:01 AM &nbsp;
			</div>






		<h3 className="blogdate">
		Wednesday, September 07, 2005
		</h3>






		<h4 className="blogitemtitle">
		Back to La Tropicana Cafe, Fremantle
		</h4>





			<div className="blogitembody">
			I've returned to Fremantle for some great architecture, cooler weather and sporadic rain, and to perform at La Tropicana Cafe again.  This time was very mellow, but the few people enjoying their coffees and lunches were responsive and appreciative... good energy!  I love the acoustics and bright northerly light in this cafe.  The coffee is great and so is the food!<br /><br />I have been chilling each day, exploring the streets and old bookshops with new friends, and visiting some attractions I missed last time in Freo.  The Shipwreck Gallery is excellent, set in an old building of sandstone and wood, full of the history of dutch encounters with Australia's west coast (and it's dangerous reefs) in the 17th and 18th centuries.  Also, I enjoyed a new Aussie indie film, Look Both Ways, which was filmed in Adelaide.  It's a fair balance of light and heavy reality, so check it out!  Still to come is a night of live music at Mojo's, and a torchlight tour of the Fremantle Prison!<br /><br />Links:<br /><a href="http://www.fremantleprison.com.au">http://www.fremantleprison.com.au</a><br /><a href="http://www.lookbothways.com.au">http://www.lookbothways.com.au</a>
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 12:07 PM &nbsp;
			</div>








		<p>
			<Link to="/blog2005/">Go back to the blog index page</Link>
		</p>
</Old2005BlogPage>
  </Layout>
)

export default OldBlogPage;
